.landing-page {
  margin-top: 15vh;
  text-align: center; }
  .landing-page .under-construction {
    color: #eee;
    font-size: calc(1rem + 0.5vw);
    margin: calc(1rem + 0.5vw); }
  .landing-page .headline {
    color: #eee;
    font-size: calc(1.5rem + 1vw);
    padding: calc(0.5rem + 0.5vw); }
    .landing-page .headline .headline-emphasis {
      font-weight: 900;
      font-size: calc(1.5rem + 1vw);
      font-style: italic;
      padding: 0 0.1rem; }
  .landing-page .input-error {
    color: #eee;
    opacity: 0;
    padding: calc(0.5rem + 0.5vw); }
  .landing-page .input-location-gps {
    cursor: pointer;
    display: block;
    margin: calc(2rem + 0.5vw) auto;
    position: relative;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    width: max-content; }
    .landing-page .input-location-gps .input-location-gps-checkbox {
      cursor: pointer;
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0; }
    .landing-page .input-location-gps .input-location-gps-checkmark {
      background-color: #eee;
      border-radius: calc(0.5rem + 0.125vw);
      height: calc(1rem + 0.25vw);
      left: 0;
      position: absolute;
      top: calc(0.15rem + 0.25vw);
      width: calc(1rem + 0.25vw); }
      .landing-page .input-location-gps .input-location-gps-checkmark:after {
        border: solid #eee;
        border-width: 0 3px 3px 0;
        content: "";
        display: none;
        height: calc(1rem + 0.25vw);
        left: calc(0.25rem + 0.25vw);
        position: absolute;
        top: calc(-0.25rem - 0.25vw);
        width: calc(0.5rem + 0.125vw);
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg); }
    .landing-page .input-location-gps .input-location-gps-text {
      color: #eee;
      font-size: calc(0.75rem + 0.75vw);
      font-style: italic;
      padding-left: calc(1.5rem + 0.5vw); }
      .landing-page .input-location-gps .input-location-gps-text::before {
        background-color: red;
        content: '';
        height: 0.2rem;
        opacity: 0;
        position: absolute;
        bottom: -0.25rem;
        transition: 0.2s;
        width: calc(100% - 1.5rem - 0.25vw); }
    .landing-page .input-location-gps input:checked ~ .input-location-gps-checkmark {
      background-color: red; }
    .landing-page .input-location-gps input:checked ~ .input-location-gps-checkmark:after {
      display: block; }
    @media (min-width: 992px) {
      .landing-page .input-location-gps:hover .input-location-gps-checkmark,
      .landing-page .input-location-gps:hover input:checked ~ .input-location-gps-checkmark {
        background-color: red;
        transition: 0.2s; }
      .landing-page .input-location-gps:hover .input-location-gps-text::before {
        opacity: 1;
        transition: 0.2s; } }
    @media (max-width: 991.98px) {
      .landing-page .input-location-gps input:checked ~ .input-location-gps-checkmark {
        background-color: red;
        transition: 0.2s; }
      .landing-page .input-location-gps input:checked ~ .input-location-gps-text::before {
        opacity: 1;
        transition: 0.2s; } }
  .landing-page .input-location-submit-button {
    background-color: #eee;
    border: none;
    border-radius: calc(1.625rem + 0.25vw);
    color: #a33;
    font-size: calc(1rem + 0.25vw);
    font-style: italic;
    font-weight: 900;
    height: calc(3.25rem + 0.5vw);
    margin-top: calc(2rem + 2vw);
    transition: 0.2s;
    width: calc(3.25rem + 0.5vw); }
    .landing-page .input-location-submit-button:hover {
      background-color: red;
      color: #eee;
      cursor: pointer;
      transition: 0.2s; }
    .landing-page .input-location-submit-button:focus {
      box-shadow: none;
      outline: none; }
  .landing-page .input-location-text-wrapper {
    margin: auto;
    position: relative;
    width: max-content; }
    .landing-page .input-location-text-wrapper .input-location-floating-placeholder {
      color: #a33;
      cursor: text;
      font-style: italic;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: calc(0.85rem + 0.25vw);
      transition: 0.2s;
      width: max-content; }
    .landing-page .input-location-text-wrapper .input-location-text-field {
      background-color: #eee;
      border: 4px solid #a33;
      border-radius: 1.25rem;
      font-size: calc(0.75rem + 0.5vw);
      height: calc(3.5rem + 0.5vw);
      padding: calc(0.25rem + 0.25vw) calc(1rem + 0.5vw) 0 calc(1rem + 0.5vw);
      width: 35vw; }
      .landing-page .input-location-text-wrapper .input-location-text-field:focus {
        border: 4px solid red;
        box-shadow: none;
        outline: none; }
      .landing-page .input-location-text-wrapper .input-location-text-field:focus ~ .input-location-floating-placeholder,
      .landing-page .input-location-text-wrapper .input-location-text-field:not(:placeholder-shown) ~ .input-location-floating-placeholder {
        font-size: calc(0.5rem + 0.25vw);
        top: calc(0.25rem + 0.1vw);
        transition: 0.1s; }
      .landing-page .input-location-text-wrapper .input-location-text-field::placeholder {
        opacity: 0; }
      @media (max-width: 767.98px) {
        .landing-page .input-location-text-wrapper .input-location-text-field {
          width: 80vw; } }
  .landing-page .search-results-modal {
    background-color: transparent;
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: fixed;
    top: -100vh;
    padding: calc(1rem + 1vw);
    transition: 0.35s ease-in;
    width: 100%;
    z-index: 1; }
    .landing-page .search-results-modal .search-results-modal-content {
      background-color: #b44;
      border: 4px solid red;
      border-radius: calc(1rem + 1vw);
      color: #eee;
      cursor: auto;
      margin: 5vh auto 0 auto;
      padding: calc(1rem + 1vw);
      width: max-content; }
      .landing-page .search-results-modal .search-results-modal-content .close-modal {
        color: #eee;
        font-size: calc(1rem + 0.75vw);
        font-weight: 700;
        margin-left: auto;
        padding-right: calc(1rem + 1vw);
        transition: 0.15s ease-in;
        width: max-content; }
        .landing-page .search-results-modal .search-results-modal-content .close-modal:hover {
          color: red;
          cursor: pointer;
          transition: 0.15s ease-in; }
      .landing-page .search-results-modal .search-results-modal-content .map {
        margin: calc(1rem + 1vw) auto;
        height: 35vh;
        width: 50vw; }
        @media (max-width: 767.98px) {
          .landing-page .search-results-modal .search-results-modal-content .map {
            height: 40vh;
            width: 70vw; } }
      .landing-page .search-results-modal .search-results-modal-content .open-google-maps-button {
        background-color: #eee;
        border: none;
        border-radius: calc(0.5rem + 0.25vw);
        color: #a33;
        font-size: calc(0.75rem + 0.75vw);
        font-weight: 700;
        padding: calc(0.25rem + 0.1vw) calc(0.5rem + 0.25vw);
        transition: 0.15s ease-in; }
        .landing-page .search-results-modal .search-results-modal-content .open-google-maps-button:focus {
          box-shadow: none;
          outline: none; }
        .landing-page .search-results-modal .search-results-modal-content .open-google-maps-button:hover {
          background-color: red;
          color: #eee;
          transition: 0.15s ease-in; }
      .landing-page .search-results-modal .search-results-modal-content .search-results-address {
        font-size: calc(1rem + 0.25vw);
        width: 75vw; }
      .landing-page .search-results-modal .search-results-modal-content .search-results-name {
        font-weight: 700;
        font-size: calc(1.25rem + 0.25vw);
        width: 75vw; }
    .landing-page .search-results-modal.modal-fade-in {
      opacity: 1;
      top: 0;
      transition: 0.35s ease-in; }

* {
  margin: 0;
  padding: 0; }

body {
  background-color: #a33;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }
